import { ref, onMounted,watch,onUnmounted } from 'vue';

export default function useVirtualScroll(pOptions) {
    const options = {
        itemSize:pOptions.itemSize ?? 34,
        recycleList : pOptions.recycleList??false,
        elementRef : pOptions.elementRef??null,
        itemsToRender: pOptions.itemsToRender??40,
        buffer:pOptions.buffer??15,
        dynamicLoading:pOptions.dynamicLoading,
        infinityScroll:pOptions.infinityScroll,
        mountedOverride: pOptions.mountedOverride,
        unmountedOverride: pOptions.unmountedOverride,
        preloadOnePage: !!pOptions.preloadOnePage
    }

    let itemsToRender = options['itemToRender']??40;
    let startFrom = 0;
    let prevDataLength = 0;
    let prevStart = null;
    let prevEndLoad = null;
    let nextPageLoaded = false;



    let prevScroll = 0;
    

    let endScrolling;
    let el = null;

    let scrollDirection = "bottom";

    let dataRef = pOptions.dataRef;
    const dataIsRef = !Array.isArray(dataRef);
  

    if(options.dynamicLoading){
        options.dynamicLoading.infinityScroll  = options.infinityScroll;
    }

    if (options.dynamicLoading) {
        options.dynamicLoading.onDataLoaded = (pClear) => {
            if(pClear){
                startFrom == 0
            }
            if(pClear && el){
                el.scrollTop = 0;
                
            }
            setItemsToRender(true);
        }
    } else if (pOptions.watchRef) {
        watch(pOptions.watchRef, () => {
            setItemsToRender(true);
        })
    } else {
        watch(pOptions.dataRef, () => {
            setItemsToRender(true);
        })
    }


    
    const scrollData = ref([]);
    

    const getDataLength = () =>{
         if(options.dynamicLoading && options.dynamicLoading.enabled){
            return options.dynamicLoading.dataLength;
        }
        return dataIsRef ? dataRef.value.length : dataRef.length;
   
    }


    const getItem = (pIndex) =>{
       
        if(options.dynamicLoading && options.dynamicLoading.enabled){
            return options.dynamicLoading.getItem(pIndex);
        }
        return dataIsRef ? dataRef.value[pIndex] : dataRef[pIndex];
      
    }


    const handleScroll = (event)=>{
        el = event.target;
        if(el.scrollTop < prevScroll){
           scrollDirection = "top";
        }else{
            scrollDirection = "bottom";
        }

        if (endScrolling) { clearTimeout(endScrolling); }
        endScrolling = setTimeout(() => {
            onScroll(event);
        }, 5);
  

    }


    const onScroll = ()=>{
        prevScroll = el.scrollTop;
        let position =  Math.round(el.scrollTop / options.itemSize);
        
        position = position > 0?position - 1:position;
    
        startFrom = position;
        updateItems();

    }

    const updateItems = function(pSkipPrevCheck){

        updateItemsToRender();

        let start = startFrom;
        let end = (start+itemsToRender);

        if(end > getDataLength()){
            start -= end - getDataLength();  
            end = (start+itemsToRender);
        }
        if(!pSkipPrevCheck && prevStart == start) return;

        prevStart = start;

        const unusedIndexes = [];

        scrollData.value.forEach((item,i)=>{
            if(item.index < start || item.index >= end){
        
                unusedIndexes.push(i); 
            }
        });

        unusedIndexes.forEach((index,i)=>{
            const vItem =  scrollData.value[index];
            if(scrollDirection === "bottom"){
                
                vItem.index = start + (scrollData.value.length - unusedIndexes.length)+i
            }else{
                vItem.index = start + i;
            }
            vItem.item = getItem(vItem.index);
            
            if(options.dynamicLoading && options.dynamicLoading.enabled){
               
                 if(!vItem.item) {
                    vItem.item = {};
                }
                if(vItem.item) vItem.isLoading = Object.keys(vItem.item).length === 0;
                   
                
            }else{
                vItem.isLoading = vItem.item?false:true;
            }
            vItem.pos = vItem.index*options.itemSize;

        })

  
        if(options.dynamicLoading){
            options.dynamicLoading.pageSize = itemsToRender;
            options.dynamicLoading.currentStart = start;
        }

        if (options.preloadOnePage) {
            end += itemsToRender;
        }
        
        if(end >= getDataLength() && options.dynamicLoading && !options.dynamicLoading.enabled){
            if ((start === 0 && !nextPageLoaded) || start !== 0) {
                options.dynamicLoading.loadNextPage();
                nextPageLoaded = true;
            }
        } else if (end >= getDataLength() && options.dynamicLoading && options.dynamicLoading.rowCount === -1) {
            if (end !== prevEndLoad && end >= options.dynamicLoading.maxRecords) {
                prevEndLoad = end;
                options.dynamicLoading.loadNextPage();
            }
        }

    }

    const updateItemsToRender = function(){
        if(el){
            itemsToRender =  Math.round(el.clientHeight/options.itemSize);
            if(itemsToRender > getDataLength()){
                itemsToRender = getDataLength();
            }

            if(el.offsetParent === null && el.clientHeight === 0 && getDataLength() > itemsToRender){
                itemsToRender = getDataLength()>options.itemsToRender?options.itemsToRender:getDataLength();
            }

            if(el.closest('.modal') && itemsToRender < 10){
                itemsToRender = 13;
            }
        }else{
            if(options.elementRef && options.elementRef.value){
                el = options.elementRef.value;
                updateItemsToRender();
                return;
            }else{
                itemsToRender = getDataLength()>options.itemsToRender?options.itemsToRender:getDataLength();
            }
        }



        itemsToRender = Math.min(itemsToRender+1,getDataLength());
        if(itemsToRender < scrollData.value.length){
            scrollData.value.splice(itemsToRender,scrollData.value.length);
        }

    }

    const setItemsToRender = function(pDataChanged){
        updateItemsToRender();
        setUpItems(pDataChanged);
         
    }

    const setUpItems = function(pDataChanged){
        if(el && el.scrollTop === 0){
            startFrom = 0;
        }

        if(prevDataLength > getDataLength()){
            startFrom = 0;
        }
        prevDataLength = getDataLength();

        if(getDataLength() < itemsToRender){

        }

        if(el && el.scrollTop > 0 && startFrom == 0 ) el.scrollTop = 0;

        if(!pDataChanged && scrollData.value.length === itemsToRender){
            updateItems(true);
            return;
        }


        /*if(startFrom > 0 && itemsToRender == scrollData.value.length){
            updateItems(true);
            console.log("here");
            return;
        }*/
        const vPrevScrollLength = scrollData.value.length;
        scrollData.value.splice(0,scrollData.value.length);

        for(var i = 0;i<itemsToRender;i++){
            const vItem = getItem(i+startFrom);
            scrollData.value.push({
                index:i+startFrom,
                item:vItem,
                isLoading:vItem?false:true,
                pos:(i+startFrom)*options.itemSize
            });
        };

        if(vPrevScrollLength !== scrollData.value.length){
            updateItems(true);
        }

    }

    function handleMounted() {
        if (options.elementRef && options.elementRef.value) {
            el = options.elementRef.value;
            setItemsToRender();
        }
        window.addEventListener("resize", setItemsToRender);
    }

    function handleUnmounted() {
        window.removeEventListener("resize", setItemsToRender);
    }

    function setItemSize(newSize) {
        options.itemSize = newSize;
    }

    if (options.mountedOverride) {
        options.mountedOverride(() => {
            handleMounted();
        });
    } else {
        onMounted(()=>{
            handleMounted();
        });
    }

    if (options.unmountedOverride) {
        options.unmountedOverride(() => {
            handleUnmounted();
        });
    } else {
        onUnmounted(()=>{
            handleUnmounted();
        });
    }


    const updateData = function(){
        setItemsToRender();
    }

    return {handleScroll,scrollData,updateData, setItemSize}

}